/* Page Width  */
.homeBody {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 20px;
  margin-bottom: 20px;
}
/* Heading Under the Slider */
.homeHeading2 {
  display: flex;
  justify-content: center; /* Center horizontally */
  text-align: center;
}

/* Content / Image under Slider */
.homeContentContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.FrontImg {
  max-width: 40%;
  height: auto;
}

.homeContent {
  max-width: 55%;
  margin-left: 20px;
}

.homeContent h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

.homeContent p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.buttonOrangeFilled,
.LearnMoreBtn {
  display: inline-block;
  padding: 10px 20px;
  margin-right: 10px;
  text-decoration: none;
  border-radius: 5px;
}

.buttonOrangeFilled {
  background-color: #fa6400;
  color: white;
  border: none;
}

.LearnMoreBtn {
  background-color: transparent;
  color: #fa6400;
  border: none;
}

/* Additional CSS to remove underline from links */
.buttonOrangeFilled a,
.LearnMoreBtn a {
  text-decoration: none;
  color: inherit; /* Ensure the link inherits the button's color */
}

/*  */
.intro-section {
  text-align: center;
  padding: 20px;
  background-color: #f8f9fa; /* Light background */
  border-radius: 8px;
  margin: 20px auto;
  max-width: 600px; /* Adjust as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.intro-title {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #333; /* Dark text color */
}

.intro-description {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #666; /* Gray text color */
  display: block;
}

.learn-more-btn {
  background-color: #007bff; /* Blue background */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.learn-more-btn a {
  color: white;
  text-decoration: none;
}

.learn-more-btn:hover {
  background-color: #0056b3; /* Darker blue on hover */
}
