nav {
  background-color: rgb(255, 255, 255);
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
}
/* Targeting Elements Links for Navigation */
nav ul {
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
nav li {
  height: 50px;
}
nav a {
  height: 100%;
  font-size: 20px; /* Adjust size for readability */
  padding: 10px 15px; /* Add some spacing around the links */
  text-decoration: none;
  display: flex;
  align-items: center;
  color: black;
}
nav a:hover {
  background-color: rgb(238, 238, 238);
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 250px;
  background-color: #d4c6f8; /* Set a solid background color */
  backdrop-filter: none; /* Remove blur effect */
  box-shadow: -10px 0 15px rgba(0, 0, 0, 0.2);
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 20px; /* Add some padding at the top */
  z-index: 1000; /* Ensure sidebar is on top */
  transition: transform 0.3s ease-in-out;
}

.sidebar.visible {
  display: flex;
}
.sidebar ul {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}
.sidebar li {
  width: 100%;
}
.sidebar a {
  width: 100%;
  padding: 10px 20px;
  text-decoration: none;
  color: black;
  display: block;
}
.sidebar a:hover {
  background-color: rgb(238, 238, 238);
}

.menuBtn {
  display: none;
}
@media (max-width: 768px) {
  .hideOnMobile {
    display: none;
  }
  .menuBtn {
    display: block;
  }
}
@media (max-width: 768px) {
  .sidebar {
    width: 100%;
  }
}

.main-content {
  transition: margin-right 0.3s;
}
.main-content.sidebar-visible {
  margin-right: 250px; /* Match the width of the sidebar */
}
