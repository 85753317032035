.about-us-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f9f9f9;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .about-us-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .about-us-img {
    width: 200px;
    height: auto;
    border-radius: 8px;
    margin-right: 20px;
  }
  
  .about-us-text {
    max-width: 500px;
  }
  
  .about-us-title {
    color: #333;
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .about-us-paragraph {
    color: #555;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  .opening-hours {
    margin-top: 30px;
  }
  
  .opening-hours-title {
    color: #333;
    font-size: 24px;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .table {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
  }
  
  .table th,
  .table td {
    padding: 10px;
    text-align: left;
  }
  
  .table th {
    background-color: #f2f2f2;
  }
  
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #f9f9f9;
  }
  
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd;
  }
  
  @media (max-width: 600px) {
    .about-us-container {
      padding: 10px;
    }
  
    .about-us-content {
      flex-direction: column;
    }
  
    .about-us-img {
      margin-right: 0;
      margin-bottom: 20px;
    }
  
    .about-us-title {
      font-size: 24
    }
  }