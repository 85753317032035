.helpHeader {
  text-align: center;
}

.faq-item {
  margin: 20px auto;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 80%; /* Adjust based on preference */
  position: relative;
  cursor: pointer;
  text-align: left;
  background-color: #e1d8f1;
}

.toggle-icon {
  font-size: 1.5em; /* Larger icon size */
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
  position: absolute; /* Position the icon absolutely within the .faq-item */
  right: 10px; /* Adjusted right position (fine-tune as needed) */
  top: calc(
    50% - 12px
  ); /* Center vertically with adjustment */ /* Remove order rule */
}

.faq-item::after {
  display: flex;
  align-items: center;
  justify-content: space-between;
  content: "";
  position: absolute; /* Remove incorrect absolute positioning */
  /* Removed other irrelevant properties */
}

.faq-item.open {
  background-color: #ccf3d8; /* Light background for the open item */
}
/* FAQ CSS*/

/* Help Form CSS*/
