.slider {
  position: relative;
  width: 100%;
  max-width: 1000px;
  height: auto;
  margin: auto;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.sliderWrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  min-width: 100%;
  height: 500px; /* Fixed height for all slides */
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end; /* Align items to the bottom */
  justify-content: center;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the slide area */
  border-radius: 10px;
}

.slide .caption {
  width: 100%; /* Make caption take full width */
  padding: 20px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6); /* Light black background */
  border: none; /* Ensure no border */
  box-shadow: none; /* Ensure no shadow */
}

.slide .caption h1,
.slide .caption h5 {
  color: white; /* Set text color to white for contrast */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
  background: none; /* Remove any background */
  margin: 0; /* Remove any default margins */
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 2rem;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 10;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}
