* {
  margin: 0;
  padding: 0;
  font-family: Georgia, "Times New Roman", Times, serif;
}
body {
  background-color: #eee7e7;
  min-height: 100vh;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-size: cover;
  background-position: center;
  color: white;
  position: relative; /* Ensure relative positioning for the header */
}

.company-name {
  font-size: 24px;
  font-weight: bold;
}

.contactInfo {
  display: flex;
  gap: 20px; /* Add gap between elements */
  align-items: center; /* Ensure vertical alignment */
}

.contactInfo span,
.contactInfo a {
  display: flex;
  align-items: center;
  gap: 5px; /* Space between icon and text */
  color: white; /* Ensure the text is white */
  text-decoration: none;
}

.contactInfo a img {
  display: flex;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .company-name {
    font-size: 20px;
    margin-bottom: 10px; /* Add space between company name and other content */
  }

  .contactInfo {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px; /* Space between contact info elements */
  }

  .contactInfo span,
  .contactInfo a {
    gap: 10px; /* Space between icon and text */
    margin-top: 10px; /* Add space between each contact info item */
  }

  .contactInfo a img {
    position: flex;
  }
}
