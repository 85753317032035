/* Contact Section */
.footerContactSection .footerContactInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footerContactSection .footerContactInfo a,
.footerContactSection .footerContactInfo p {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease-in-out; /* Smooth color transition */

}

.footerContactSection .footerContactInfo a:hover {
  color: #297fe2; /* Change to desired hover color */
}

.footerContactSection .footerContactInfo .whatsapp-link {
  display: flex;
  align-items: center;
  padding: 10px 20px; /* Add padding for better click area */
  background-color: #25d366; /* WhatsApp green background */
  color: #ffffff; /* White text */
  border-radius: 5px; /* Rounded corners */
  text-decoration: none; /* Remove underline */
  transition: background-color 0.3s ease-in-out; /* Smooth background transition */
}

.footerContactSection .footerContactInfo .whatsapp-link:hover {
  background-color: #1ebd60; /* Slightly darker green on hover */
}

.footerContactSection .footerContactInfo  {
  margin-right: 8px; /* Space between icon and text */
}

.footerContactSection .footerContactInfo  {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.footerContactSection .footerContactInfo .social-icons a:hover {
  color: #297fe2; /* Change to desired hover color */
}






/* Follow Us Section */
.follow-us-section a {
  margin-right: 10px;
  transition: transform 0.3s ease-in-out; /* Smooth scale effect */
}

.follow-us-section a:hover {
  transform: scale(1.1); /* Slightly larger on hover */
}

.follow-us-section img {
  padding: 5px;
  border-radius: 50%;
  transition: box-shadow 0.3s ease-in-out; /* Smooth shadow effect */
}

.follow-us-section img:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
}

/* Silver Text Section */
.silver-text {
  color: white;
}

.silver-text a {
  color: rgb(255, 255, 255); /* Slightly more distinct color */
  text-decoration: underline; /* Underline to indicate it's a link */
  font-weight: bold; /* Bold text to make it stand out */
  transition: color 0.3s ease-in-out, text-shadow 0.3s ease-in-out; /* Smooth transitions */
}

.silver-text a:hover {
  color: rgb(54, 91, 212); /* Brighter color on hover */
  text-decoration: none; /* Optionally remove underline on hover */
}
.footerHeading {
  background: linear-gradient(45deg, #5fd847, #4a90e2);
  background-clip: text;
  color: transparent;
  font-weight: bold;
}

.companyRights{
  color: white;
  text-align: right;
  margin-left: auto;
}