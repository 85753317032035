.features-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;
  font-family: Arial, sans-serif;
}

.feature {
  margin-bottom: 20px;
}

.feature strong {
  font-size: 1.2em;
  margin-bottom: 5px;
  display: block;
}

.feature p {
  margin: 0;
  line-height: 1.5;
}
