/* CommonQs.css */
.faq-section {
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-color: #e6f7e6; /* Light green background */
  padding: 20px;
  border-radius: 8px;
}

.faq-max-width {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.faq-text1 {
  text-align: center;
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Add space between FAQ items */
}

.faq-list-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.faq-question {
  font-style: normal;
  text-align: left;
  font-weight: 600;
  margin-bottom: 5px;
}

.faq-answer {
  text-align: left;
}

.faq-content1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.faq-text3 {
  text-align: center;
}

@media (max-width: 991px) {
  .faq-max-width {
    gap: 15px;
  }
}

@media (max-width: 767px) {
  .faq-text1 {
    text-align: left;
  }
  .faq-list {
    gap: 15px;
  }
  .faq-button {
    width: 100%;
  }
}
