.servicesBody {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 20px;
  margin-bottom: 20px;
}
.contact-container-custom {
  text-align: center;
  margin: 20px;
  font-family: Arial, sans-serif;
}

.contact-info-custom {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.contact-info-custom a,
.contact-info-custom button {
  margin: 10px;
  text-decoration: none;
  color: #000;
}

.buttonOrangeFilled-custom {
  background-color: #ff6600;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
}

.buttonOrangeFilled-custom .link-custom {
  color: white;
  text-decoration: none;
}

.phone-custom,
.whatsapp-custom,
.email-custom {
  display: flex;
  align-items: center;
  font-size: 1em;
  color: #000;
  text-decoration: none;
}

.phone-custom img,
.whatsapp-custom img,
.email-custom img {
  margin-right: 8px;
}

.contact-info-custom a:hover,
.buttonOrangeFilled-custom:hover {
  opacity: 0.8;
}
